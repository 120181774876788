<template>
  <div class="form-inline flex-nowrap gx-3">
    <div class="form-wrap w-150px">
      <select class="form-select form-select-md form-control" v-model="selectedAction">
        <option value="">{{ $t('Bulk Action') }}</option>
        <option v-for="(x,xi) in actions" :key="'ba_opt_'+xi" :value="xi">{{ $t(x) }}</option>
      </select>
    </div>
    <div class="btn-wrap">
      <span class="d-none d-md-block">
        <nk-button dim outline type="light" v-on:click="performAction" :disabled="!selectedAction">{{ $t('Apply') }}</nk-button>
      </span>
      <span class="d-md-none">
        <nk-button dim outline type="light" v-on:click="performAction" is-icon-only-button :disabled="!selectedAction">
          <nio-icon icon="ni-arrow-right"></nio-icon>
        </nk-button>
      </span>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
export default {
  name: "BulkAction",
  props:{
    actions: {
      required: true,
      type: Object,
    },
  },
  emits: ['performAction'],
  setup(props,{emit}){
    let selectedAction = ref('')
    const performAction = ()=>{
      emit('performAction', selectedAction.value)
    }
    return{
      performAction,
      selectedAction,
    }
  }
}
</script>
